import './src/styles/global.css'

export const onRouteUpdate = () => {
    const subdomain = "careers.eulerity.com"
    const redirectUrl = "https://boards.greenhouse.io/eulerity"

    if (typeof window !== "undefined") {
        const host = window.location.hostname;

        if (host.includes(subdomain)) {
            window.location.href = redirectUrl
        }
    }
}